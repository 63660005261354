import * as R from 'ramda'
import {getImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import classNames from 'classnames'
import loadable from '@loadable/component'
import React, {useRef} from 'react'

import {relatedPropTypes} from 'helpers/propTypes'
import {slugTransformer} from 'helpers/utils'
import DateText from 'components/UI/Date'
import Description from 'components/UI/Description'
import Heading from 'components/UI/Heading'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const Related = ({
  cards,
  title,
  description,
  ctaTitle,
  ctaLink,
  cardSize,
  buttonsPosition,
  prefix,
  isServices,
}) => {
  const RoundButton = loadable(() => import('components/UI/RoundButton'))
  const RoundButtonSlider = loadable(() =>
    import('components/UI/RoundButtonSlider'),
  )

  const Media = loadable(() => import('components/UI/Media'))

  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const isMobile = useIsMobile()

  const ref = useRef(null)
  const refCard = useRef(null)
  const showNavButtons = R.length(cards) <= 4 && !cardSize

  function scrollSlider(direction) {
    const {width} = refCard.current.getBoundingClientRect()
    const newWidth = cardSize === 'wide' ? width + 70 : (width + 40) * 3

    if (direction === 'end') {
      ref.current.scrollLeft += newWidth
    } else {
      ref.current.scrollLeft -= newWidth
    }
  }

  const renderCards = () =>
    mapIndexed((card, index) => {
      const {
        node,
        imageOrIcon,
        slug,
        name,
        image,
        type,
        publicationDate,
        node_locale,
        metaDescription,
      } = card

      const gatsbyImage =
        getImage(image) ||
        R.pathOr(null, ['node', 'image', 'gatsbyImageData'], card)

      const renderImage = () => (
        <Media className={classes.thumbnailImage} data={gatsbyImage} />
      )

      const renderSvg = () => (
        <img
          className={classes.thumbnailImage}
          src={imageOrIcon.file.url}
          alt="imageOrIcon.file.url"
          loading="lazy"
        />
      )

      const slugToService = () =>
        `${prefix}${slugTransformer(slug) || slugTransformer(node.slug)}`

      return isServices ? (
        <Link
          to={slugToService()}
          className={classes.cardFrontmatter}
          key={index}
          ref={index === 0 ? refCard : null}
        >
          <div className={classes.subService}>
            <div className={classes.imageWrap}>
              <img
                src={R.pathOr('', ['file', 'url'], imageOrIcon)}
                className={classes.subLevelImage}
                alt={R.pathOr('', ['imageOrIcon', 'description'], node)}
                loading="lazy"
              />
            </div>
            <div className={classNames(classes.title, classes.serviceTitle)}>
              {name || R.pathOr(' ', ['title', 'title'], node)}
            </div>
          </div>
        </Link>
      ) : (
        <Link
          to={`${prefix}${slug || node.slug}`}
          className={classNames(classes.cardFrontmatter, {
            [classes.cardFrontmatterWide]: cardSize === 'wide',
          })}
          key={index}
          ref={index === 0 ? refCard : null}
        >
          {cardSize === 'wide' ? (
            <>
              {imageOrIcon ? renderSvg() : renderImage()}
              <div className={classes.textWrapper}>
                <Title
                  type="subTitle"
                  variant="h3"
                  hat={
                    R.pathOr('', ['name'], type) ||
                    R.pathOr('', ['type', 'name'], node)
                  }
                >
                  {R.pathOr('', ['title'], card) ||
                    R.pathOr('', ['title'], node)}
                </Title>
                <div className={classes.reverse}>
                  <DateText
                    timeCode={
                      publicationDate || node.publicationDate || node.createdAt
                    }
                    node_locale={node_locale || node.node_locale}
                  />
                </div>
                <p className={classes.slideDescription}>
                  {R.pathOr(
                    ' ',
                    ['metaDescription', 'metaDescription'],
                    node,
                  ) || R.pathOr(' ', ['metaDescription'], metaDescription)}
                </p>
              </div>
            </>
          ) : (
            <>
              {imageOrIcon ? renderSvg() : renderImage()}
              <div className={classes.title}>
                {(card.title && card.title.title) ||
                  name ||
                  R.pathOr(' ', ['title', 'title'], node)}
              </div>
            </>
          )}
        </Link>
      )
    }, R.slice(0, 10, cards))

  return (
    <div className={classes.setZIndex}>
      <Section hasPaddingSide={false} hasSmallPadding style={{zIndex: 30}}>
        <div className="sideWrapper">
          <div
            className={classNames(classes.container, {
              [classes.buttonsCentered]: buttonsPosition === 'center',
              [classes.buttonsBottom]: buttonsPosition === 'bottom',
            })}
          >
            <Heading>
              {title && (
                <Title variant="h2" type="subTitle">
                  {title}
                </Title>
              )}
              {description && (
                <Description hasMarginTop hasMarginBottom>
                  {description}
                </Description>
              )}
              {ctaLink && ctaTitle && (
                <RoundButton color="primary" arrow href={ctaLink}>
                  {ctaTitle}
                </RoundButton>
              )}
            </Heading>
            {!isMobile && !showNavButtons && (
              <RoundButtonSlider
                increment={() => scrollSlider('end')}
                decrement={() => scrollSlider('start')}
              />
            )}
          </div>
        </div>

        <div className={`${classes.scrollable} sideWrapper`} ref={ref}>
          <div className={classes.scrollableInner}>{renderCards()}</div>
        </div>
      </Section>
    </div>
  )
}

Related.propTypes = relatedPropTypes.isRequired

export default Related
