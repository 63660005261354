import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, spacing}) => ({
  miniPictures: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: spacing(6.25),
    marginBottom: spacing(6.25),

    '& div:nth-first-of-type': {
      marginLeft: spacing(0),
    },
    '& div:nth-last-of-type': {
      marginRight: spacing(0),
    },
    '& image': {
      [breakpoints.up('md')]: {
        display: 'block',
      },
    },
    '& a': {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none !important',
      alignItems: 'center',
    },
    '& p': {
      color: `${palette.text.dark}!important`,
    },
    '& p > span': {
      [breakpoints.up('md')]: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
      },
    },
    [breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
      margin: 'auto',
    },
  },

  deliveryModel: {
    flex: '1 0 21%',
    height: '100%',
    margin: spacing(6.25, 5),
    [breakpoints.down('sm')]: {
      margin: spacing(2),
    },
    '& p > span': {
      alignItems: 'flex-start',
      marginTop: spacing(1),
    },
  },

  description: {
    textAlign: 'left',
  },

  imageWrap: {
    height: 130,
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      width: '90%',
      margin: 'auto',
      marginBottom: spacing(3),
    },
  },
  subLevelImage: {
    height: '100%',
  },

  title: {
    marginTop: spacing(3),
    textAlign: 'center',
  },
}))

export default useStyles
