import loadable from '@loadable/component'
import React, {useEffect, useState} from 'react'

import {switchFormPropTypes} from 'helpers/propTypes'

const Form =
  process.env.GATSBY_WEBSITE === 'kls' &&
  loadable(() => import('components/UI/FormKls'))

const HubspotContactForm =
  (process.env.GATSBY_WEBSITE === 'keyrus' ||
    process.env.GATSBY_WEBSITE === 'keyrus-management' ||
    process.env.GATSBY_WEBSITE === 'keyrus' ||
    process.env.GATSBY_WEBSITE === 'keyrus-fondation' ||
    process.env.GATSBY_WEBSITE === 'keyrus-nearshoring') &&
  loadable(() => import('components/UI/HubspotContactForm'))

const ParllayContactForm =
  process.env.GATSBY_WEBSITE === 'keyrus-china' &&
  loadable(() => import('components/UI/ParllayContactForm'))

const SwitchForm = ({
  data,
  hubSpotCountry,
  isContact,
  locale,
  location,
  locationState,
  redirection,
  titleVariant,
  websiteSource,
  isArticle,
  hasNoTitle,
  isPopup,
  formId,
}) => {
  const [componentToRender, setComponentToRender] = useState(null)

  useEffect(() => {
    switch (process.env.GATSBY_WEBSITE) {
      case 'keyrus':
        setComponentToRender(
          <HubspotContactForm
            data={data}
            hubSpotCountry={hubSpotCountry}
            isContact={isContact}
            locale={locale}
            location={location}
            redirection={redirection}
            titleVariant={titleVariant}
            isArticle={isArticle}
            hasNoTitle={hasNoTitle}
            isPopup={isPopup}
            formId={formId}
          />,
        )
        break

      case 'kls':
        setComponentToRender(
          <Form
            data={data}
            isContact={isContact}
            locationState={locationState}
            redirection={redirection}
            websiteSource={websiteSource}
            isArticle={isArticle}
            hasNoTitle={hasNoTitle}
          />,
        )
        break

      case 'keyrus-china':
        setComponentToRender(<ParllayContactForm formData={data} isContact />)
        break

      default:
        setComponentToRender(
          <HubspotContactForm
            data={data}
            hubSpotCountry={hubSpotCountry}
            isContact={isContact}
            locale={locale}
            location={location}
            redirection={redirection}
            titleVariant={titleVariant}
            isArticle={isArticle}
            hasNoTitle={hasNoTitle}
            isPopup={isPopup}
            formId={formId}
          />,
        )
    }
  }, [
    data,
    hubSpotCountry,
    isContact,
    locale,
    location,
    redirection,
    titleVariant,
    isArticle,
    hasNoTitle,
    isPopup,
    formId,
    locationState,
    websiteSource,
  ])

  return <>{componentToRender}</>
}

export default SwitchForm

SwitchForm.propTypes = switchFormPropTypes.isRequired
