import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({palette, layout, fontSizes, breakpoints, spacing}) => ({
    setZIndex: {
      position: 'relative',
      zIndex: 15,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing(10),
      '& h1': {
        fontSize: fontSizes.largeDescription,
      },
      [breakpoints.down('sm')]: {
        marginBottom: spacing(5),
      },
    },
    buttonsCentered: {
      alignItems: 'center',
    },
    buttonsBottom: {
      alignItems: 'flex-end',
    },
    listCards: {
      display: 'flex',
      marginTop: spacing(5.75),
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginBottom: spacing(5),
      },
    },
    scrollable: {
      display: 'flex',
      overflowX: 'hidden',
      scrollBehavior: 'smooth',
    },
    scrollableInner: {
      [breakpoints.up('md')]: {
        display: 'flex',
        '& > a:last-of-type': {
          paddingRight: spacing(5),
        },
      },
    },
    cardFrontmatter: {
      marginRight: spacing(5),
      textDecoration: 'none',
      width: '100%',
      color: 'inherit',
      display: 'flex',
      flexDirection: 'column',
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
      [breakpoints.up('md')]: {
        width: layout.related.width,
      },
      [breakpoints.down('sm')]: {
        margin: spacing(5, 0, 5, 0),
        alignItems: 'center',
      },
    },
    cardFrontmatterWide: {
      flexDirection: 'row',
      [breakpoints.up('md')]: {
        marginRight: spacing(8.75),
        flex: 'none',
        alignItems: 'center',
        width: 'auto',
      },
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    thumbnailImage: {
      height: 210,
      minWidth: 260,
      borderRadius: spacing(5, 0),
      transform: 'translateZ(0)',
      [breakpoints.down('sm')]: {
        width: '100%',
        height: 'auto',
      },
    },
    textWrapper: {
      marginLeft: spacing(4),
      maxWidth: layout.related.maxWidth,
      '& h1': {
        paddingBottom: spacing(1),
      },
      '& > p:first-of-type': {
        fontSize: 18,
      },
      '& > p:last-of-type': {
        marginBottom: 0,
        fontFamily: 'CamptonLight',
        fontSize: 18,
        color: palette.text.grey,
      },
      [breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: spacing(2.5),
        '& > p:first-of-type': {
          marginBottom: spacing(1.25),
        },
      },
    },
    title: {
      margin: 'auto',
      marginTop: 30,
      fontFamily: 'CamptonMedium',
      fontSize: 20,
      fontWeight: 500,
      color: palette.text.dark,
      fontStyle: 'normal',
      '& i': {
        color: palette.primary.main,
        fontStyle: 'normal',
      },
    },
    reverse: {
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    primaryTitle: {'& i': {color: palette.primary.main}},
    secondaryTitle: {'& i': {color: palette.secondary.main}},
    tertiaryTitle: {'& i': {color: palette.tertiary.main}},
    subService: {
      flex: '1 0 21%',
      margin: spacing('auto', 4),
      height: '100%',
    },
    subLevelImage: {
      height: '100%',
    },
    imageWrap: {
      height: 130,
      display: 'flex',
      justifyContent: 'center',
    },
    serviceTitle: {
      textAlign: 'center',
      fontSize: 18,
    },
  }),
)

export default useStyles
