import * as R from 'ramda'
import {arrayOf, number, objectOf, shape, string} from 'prop-types'
import {Link} from 'gatsby'
import React from 'react'

import {slugTransformer, stringToSlug} from 'helpers/utils'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './styles'

const DeliveryModels = ({deliveryModels, descriptionShrink}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  const {callToAction, contentSections, slug, surtitle} = R.path(
    [],
    deliveryModels,
  )

  return (
    <Section>
      <div className={classes.miniPictures}>
        <Title type="title" variant="h1">
          {surtitle}
        </Title>
        {contentSections &&
          mapIndexed((contentSection, mapIndex) => {
            const {title} = contentSection
            const hashSlug = stringToSlug(title)
            const imageTitle = R.path(['image', 'title'], contentSection)
            const imageUrl = R.path(['image', 'file', 'url'], contentSection)
            const description = R.path(['description'], contentSection)

            return (
              <div key={mapIndex} className={classes.deliveryModel}>
                <Link to={`./delivery-models#${hashSlug}`}>
                  <div className={classes.imageWrap}>
                    <img
                      src={imageUrl}
                      className={classes.subLevelImage}
                      alt={imageTitle}
                      loading="lazy"
                    />
                  </div>
                  <p className={classes.title}>{contentSection.title}</p>
                  <p className={classes.description}>
                    <RawText text={description} shrink={descriptionShrink} />
                  </p>
                </Link>
              </div>
            )
          }, contentSections)}
      </div>
      <RoundButton isCentered arrow href={`${slugTransformer(slug)}`}>
        {callToAction}
      </RoundButton>
    </Section>
  )
}

DeliveryModels.propTypes = {
  deliveryModels: objectOf({
    callToAction: string,
    contentSections: arrayOf(
      shape({
        description: shape({raw: string}),
        image: shape({
          file: shape({contentype: string, fileName: string, url: string}),
          title: string,
        }),
        title: string,
      }),
    ),
    slug: string,
    surtitle: string,
  }),
  descriptionShrink: number,
}

DeliveryModels.defaultProps = {
  deliveryModels: shape({
    callToAction: '',
    contentSections: arrayOf(
      shape({
        description: shape({raw: string}),
        image: shape({
          file: shape({contentype: '', fileName: '', url: ''}),
          title: '',
        }),
        title: '',
      }),
    ),
    slug: '',
    surtitle: '',
  }),
  descriptionShrink: 80,
}

export default DeliveryModels
