import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import loadable from '@loadable/component'
import React from 'react'

import {
  allContentfulKeyPlayPropTypes,
  formPropTypes,
  insightsPropTypes,
  locationPropTypes,
  pageContextPropTypes,
  servicesPropTypes,
} from 'helpers/propTypes'
import {formRedirection} from 'helpers/utils'
import {getServices} from 'helpers/services'
import DeliveryModels from 'components/Services/DeliveryModels'
import Description from 'components/UI/Description'
import Related from 'components/UI/Related'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import SwitchForm from 'components/UI/SwitchForm'
import Title from 'components/UI/Title'
import TopLevelServices from 'components/Services'

const Services = ({data, pageContext, location}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const approach = R.pathOr(null, ['approach'], countryData)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))
  const {navLocale, countryCode, salesforce} = countryData
  const {metaTitle, metaDescription, name, homepageTitle, relatedEntries} =
    R.pathOr(null, ['contentfulServices'], data)

  const shortDescription = R.pathOr(
    null,
    ['contentfulServices', 'shortDescription', 'shortDescription'],
    data,
  )
  const keyPlays = R.map(({node}) => node, data.allContentfulKeyPlay.edges)

  const Insights = R.pathOr(null, ['allContentfulInsight', 'edges'], data)

  const formData = R.pathOr(null, ['contentfulForm'], data)

  const {services} = R.pathOr(null, ['contentfulCountry'], data)

  const topLevelServices =
    services && R.pathOr(null, ['topLevelServices'], services)
  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)

  const {deliveryModels} = R.pathOr(null, ['contentfulCountry'], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" left={0} top={54} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={30} />
          <Decoration webSiteName="kls" svg="left" left={-80} top={0} />
          <Decoration webSiteName="kls" svg="right" right={0} top={34} />
        </Decorations>
        <Section hasPaddingBottom={false} hasVerySmallPadding>
          <Title type="title" variant="h1" hat={name}>
            {homepageTitle}
          </Title>
          <Description type="largeDescription">{shortDescription}</Description>
          {data.contentfulCountry.services !== null && (
            <TopLevelServices
              sections={approach}
              data={getServices(data, topLevelServices)}
            />
          )}
        </Section>
        {process.env.GATSBY_WEBSITE === 'kls' && (
          <DeliveryModels
            deliveryModels={deliveryModels}
            descriptionShrink={80}
          />
        )}
        {keyPlays && (
          <Related
            cards={keyPlays}
            title={R.pathOr(null, ['relatedKeyPlaysTitle'], relatedEntries)}
            description={R.pathOr(
              null,
              [
                'relatedKeyPlaysShortDescription',
                'relatedKeyPlaysShortDescription',
              ],
              relatedEntries,
            )}
            ctaTitle={R.pathOr(
              null,
              ['relatedKeyPlaysCallToAction'],
              relatedEntries,
            )}
            ctaLink="../playbook"
            buttonsPosition="center"
            prefix="../playbook/"
          />
        )}
        <SwitchForm
          data={formData}
          location={location}
          locale={navLocale}
          redirection={formRedirection(siteURL, countryCode, navLocale, 'lead')}
          redirect={formRedirection(siteURL, countryCode, navLocale, 'lead')}
          websiteSource="services"
          hubSpotCountry={salesforce}
        />
        {Insights && (
          <Related
            cards={data.allContentfulInsight.edges}
            title={relatedEntries.relatedUpdatesTitle}
            description={
              relatedEntries.relatedUpdatesShortDescription
                .relatedUpdatesShortDescription
            }
            ctaTitle={relatedEntries.relatedUpdatesCallToAction}
            ctaLink="../insights"
            cardSize="wide"
            buttonsPosition="bottom"
            prefix="../insights/"
          />
        )}
      </div>
    </>
  )
}

Services.propTypes = {
  data: shape({
    contentfulServices: servicesPropTypes,
    allContentfulKeyPlay: allContentfulKeyPlayPropTypes,
    contentfulLeadForm: formPropTypes,
    allContentfulInsight: insightsPropTypes,
  }).isRequired,
  location: locationPropTypes.isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

export default Services

export const pageQuery = graphql`
  query templateServices(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulServices(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      ...servicesFields
      relatedEntries {
        name
        relatedKeyPlaysCallToAction
        relatedKeyPlaysShortDescription {
          relatedKeyPlaysShortDescription
        }
        relatedKeyPlaysTitle
        relatedUpdatesCallToAction
        relatedUpdatesShortDescription {
          relatedUpdatesShortDescription
        }
        relatedUpdatesTitle
      }
    }
    allContentfulKeyPlay(
      filter: {
        node_locale: {eq: $nodeLocale}
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
      }
    ) {
      edges {
        node {
          contentful_id
          id
          title {
            title
          }
          slug
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    contentfulForm(
      name: {regex: $entityRegEx}
      node_locale: {eq: $nodeLocale}
    ) {
      ...FormFields
    }
    allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
      }
      sort: {publicationDate: DESC}
      limit: 20
    ) {
      edges {
        node {
          ...insightFields
          metaDescription {
            metaDescription
          }
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            description
          }
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
      name
      id
      countryCode
      isMultilang
      navLocale
      salesforce
      services {
        topLevelServices {
          entryId
          subLevelServices {
            entryId
          }
        }
      }
      deliveryModels {
        callToAction
        slug
        surtitle
        contentSections {
          title
          description {
            raw
          }
          image {
            title
            file {
              url
              fileName
              contentType
            }
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
